<template>
<div id="header" style="margin-top:20px; margin-left: 15px; margin-right: 15px;">
    <b-navbar type="light" toggleable="md" variant="light" class="breadcrumb" style="border-radius: 4px !important;" id="fadeinS">

            <b-navbar-brand>
                <img src="./logo-alpha.png" width="30" height="30" class="d-inline-block align-top" alt="">
                BFEAC
            </b-navbar-brand>
            <b-navbar-toggle target="a"></b-navbar-toggle>

            <b-collapse id="a" is-nav>
                <b-navbar-nav>
                    <b-nav-item href="#/">首页</b-nav-item>
                    <b-nav-item href="#/list">封禁列表</b-nav-item>
                    <b-nav-item href="#/about">关于</b-nav-item>
                    <b-nav-item href="#/waterfall" v-if="this.permlevel() >= 16">信息流</b-nav-item>
                    <b-nav-item href="https://space.bilibili.com/1811762468" target="_blank"><i class="fa-brands fa-bilibili" style="font-size: small;"></i>&nbsp;BiliBili</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item v-on:click="$bvModal.show('login_box')" v-if="current_user_details == null" id="login_button"><i class="fa-solid fa-arrow-right-to-bracket"></i>&nbsp;登陆</b-nav-item>
                    <b-nav-item-dropdown v-bind:html="current_user_details['nickname']" v-else right>
                        <b-dropdown-item href="#/user/notify">
                            <b-icon icon="bell" aria-hidden="true"></b-icon> 通知 ({{ current_user_details['unread_msg_count'] }} 条未读)
                        </b-dropdown-item>
                        <b-dropdown-item href="#" v-on:click="$bvModal.show('change_password_box')">
                            <b-icon icon="pen" aria-hidden="true"></b-icon> 修改密码
                        </b-dropdown-item>
                        <b-dropdown-item href="#/admin" v-if="this.permlevel() >= 255">
                            <b-icon icon="speedometer2" aria-hidden="true"></b-icon> 管理员面板
                        </b-dropdown-item>
                        <b-dropdown-item href="#" v-on:click="logout()">
                            <b-icon icon="x-circle" aria-hidden="true"></b-icon> 注销</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item href="#/register" v-if="current_user_details == null" id="register_button"><i class="fa-solid fa-address-card"></i>&nbsp;注册</b-nav-item>
                    <b-nav-form right @submit.prevent="search_case">
                        <b-overlay :show="is_searching">
                            <b-form-input class="mr-sm-1" style="width: 145px;" placeholder="查询ID..." v-model="search_id" @keyup.enter="search_case" disabled:="is_searching">
                            </b-form-input>
                            <b-button variant="outline-secondary" class="my-2 my-sm-0 border-0" v-on:click="search_case" disabled:="is_searching" right>
                                <b-icon icon="search" aria-hidden="true"></b-icon>
                            </b-button>
                        </b-overlay>

                    </b-nav-form>
                </b-navbar-nav>
            </b-collapse>
    </b-navbar>
    <b-modal id="search_result" title="搜索结果">
        <b href="#" v-for="(item, index) in search_result" :key="index" >Case {{ item['case_id'] }}, 举报时间: {{ item.created_at }}, 受理状态: {{ item.current_status }}</b>
    </b-modal>
    <b-modal id="login_box" title="登陆" @ok="login_handle_Ok">
        <b-overlay :show="is_logging" rounded="sm">
            <b-alert :show="login_error != null" variant="danger" refs="login_failed_message">{{ login_error }}</b-alert>
            <b-card-text>
                <b-form>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label">邮箱</label>
                        <div class="col-sm-10">
                            <b-form-input v-model="login_email" type="text" class="form-control" required id="login_username"></b-form-input>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">密码</label>
                        <div class="col-sm-10">
                            <b-form-input v-model="login_password" type="password" class="form-control" required id="login_password"></b-form-input>
                        </div>
                    </div>
                    <div>
                        <b-link href="#/forgotten_password" v-on:click="$bvModal.hide('login_box')">忘记密码?</b-link>
                    </div>
                    <vue-recaptcha
                        sitekey="6LeXOQ8hAAAAAOs5mlB3r72NEaPqTQk17FsqYFv4"
                        :loadRecaptchaScript="true"
                        recaptchaHost="www.recaptcha.net"
                        @verify="verifyMethod"
                        ref="recaptcha_login"
                    >
                    </vue-recaptcha>
                </b-form>
            </b-card-text>
        </b-overlay>
    </b-modal>
    <b-modal id="change_password_box" title="修改密码" @ok="handleOk_change_pw">
        <b-alert :show="change_pw_error != null" variant="danger">{{ change_pw_error }}</b-alert>
        <b-form>
            <div class="form-group row">
                <label for="inputOldPassword" class="col-sm-2 col-form-label">旧密码</label>
                <div class="col-sm-10">
                    <b-form-input v-model="change_pw_old_password" type="text" class="form-control"></b-form-input>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputOldPassword" class="col-sm-2 col-form-label">新密码</label>
                <div class="col-sm-10">
                    <b-form-input v-model="change_pw_new_password_1" type="password" class="form-control"></b-form-input>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputOldPassword" class="col-sm-2 col-form-label">确认密码</label>
                <div class="col-sm-10">
                    <b-form-input v-model="change_pw_new_password_2" type="password" class="form-control"></b-form-input>
                </div>
            </div>
        </b-form>
    </b-modal>
</div>
</template>

<script>
import {is_login, set_permlevel, get_permlevel, set_eauid} from '@/cookies'
import {request} from '@/api'
import { VueRecaptcha } from 'vue-recaptcha';

export default{
    inject: ['reload'],
    components: { VueRecaptcha },
    data() {
        return {
            search_result: [],
            box_title: "",
            box_body: "",
            search_id: "",

            login_email: null,
            login_password: null,
            login_error: null,
            login_code: null,
            code_url: null,
            is_logging: false,
            current_user_details: null,
            change_pw_error: null,
            change_pw_old_password: null,
            change_pw_new_password_1: null,
            change_pw_new_password_2: null,
            permlevel: get_permlevel,
            is_searching: false,
        }
    },
    methods: {
        async search_case() {
            try {
                this.is_searching = true
                this.search_result = await request('GET', '/case/EAID/' + this.search_id)
                  if (this.search_result.length === 1) {
                      await this.$router.push({name: 'case', params: {id: this.search_result[0]['case_id']}})
                  } else {
                      this.$bvModal.show("search_result")
                  }
            } catch (e) {
                await this.$bvModal.msgBoxOk(e[1], {title: '警告!'})
            }
            this.is_searching = false
        },
        logout(){
            if (is_login()){
                this.current_user_details = null
                request('GET', '/user/logout').then(() => {
                    // eslint-disable-next-line no-import-assign
                    set_permlevel(0)
                    this.$bvModal.msgBoxOk("注销成功!")
                        .then(() => {this.$router.replace('/')})
                })
            }
        },
        login_handle_Ok(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.login_error = null
            if (this.login_code === null) {
                this.login_error = "请进行人机认证!"
                return
            }
            this.is_logging = true
            this.login_submit()
                .then(() => {
                    this.login_error = null
                    this.$nextTick(() => {
                        this.$bvModal.hide('login_box')
                        this.$bvModal.msgBoxOk("登录成功!")
                            .then(() => {this.reload()})
                        })

                })
                .catch(e => {
                    this.login_error = e[1]
                })
                .finally(() => {
                    this.is_logging = false
                    this.$refs.recaptcha_login.reset();
                })
        },
        handleOk_change_pw(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.change_pw_error = null
            if (!(this.change_pw_new_password_2 && this.change_pw_new_password_1 && this.change_pw_old_password)) {
                this.change_pw_error = "输入不完整!"
                return
            }
            if (this.change_pw_new_password_1 !== this.change_pw_new_password_2) {
                this.change_pw_error = "两次密码输入不一致!"
                return
            } else if (this.change_pw_new_password_1.length < 6 || this.change_pw_new_password_1.length > 16) {
                this.change_pw_error = "密码不符合安全要求! 位数需要在6~16位!"
                return
            } else if (this.change_pw_new_password_1 === this.change_pw_old_password) {
                this.change_pw_error = "新旧密码不能一样!"
                return
            }
            request('POST', '/user/self/new_password', {
                old_password: this.change_pw_old_password, new_password: this.change_pw_new_password_1
            })
                .then(() => {
                    this.$bvModal.hide('change_password_box')
                    this.$bvModal.msgBoxOk("修改成功! 系统自动注销...")
                        .then(() => {this.logout()})
                })
                .catch(e => {
                    this.change_pw_error = e
                })
        },
        async login_submit() {
            await request('POST', '/user/login', {
                email: this.login_email, password: this.login_password, captcha: this.login_code
            })

        },
        verifyMethod(response){
            this.login_code = response;
        }
    },
    created() {
        if (is_login()) {
                request('GET', '/user/self_info')
                    .then(data => {
                        this.$nextTick(() => {
                            this.current_user_details = data
                            set_permlevel(data['perm_level'])
                            set_eauid(data['bind_EA_userId'])
                        })
                    })
                    .catch(() => {
                        this.current_user_details = null
                    })
            }
        },
    watch: {
        current_user_details: {
            handler (new_value, old_value){
                console.log(new_value, old_value)
                // 监控未读消息是否在增多.
                if (new_value === null) {
                    return;
                } else if (old_value === null && new_value['unread_msg_count'] === 0) {
                    return;
                } else if (new_value['unread_msg_count'] <= old_value['unread_msg_count']) {
                    return;
                }
                this.$bvToast.toast('您有' + new_value['unread_msg_count'] + '消息未读! 点击以查看', {
                    title: '系统消息',
                    href: '#/user/notify',
                    autoHideDelay: 5000
                })
            },
            immediate: true
        }
    }
}
</script>