var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-card',{staticClass:"shadow",attrs:{"id":"fadein"}},[_c('b-row',[_c('b-col',{attrs:{"size":"sm"}},[_c('b-form-group',{staticClass:"form-inline"},[_c('b-select',{staticClass:"mr-3",attrs:{"options":[
                              { text: '战地1 (' + _vm.case_num_game['1'] + ')', value: '1' },
                              { text: '战地5 (' + _vm.case_num_game['5'] + ')', value: '5' },
                              { text: '未归类 (' + _vm.case_num_game['0'] + ')', value: '0' } ]},on:{"change":function($event){_vm.currentPage = 1;_vm.$refs.table.refresh()}},model:{value:(_vm.select_game),callback:function ($$v) {_vm.select_game=$$v},expression:"select_game"}}),(_vm.screenWidth > 576)?_c('b-form-radio-group',{ref:"selection",attrs:{"id":"btn-radios-2","options":[
                            { text: _vm.status_name[6] + ' (' + _vm.case_num['-1'] + ')', value: '-1' },
                            { text: _vm.status_name[0] + ' (' + _vm.case_num['0'] + ')', value: '0' },
                            { text: _vm.status_name[1] + ' (' + _vm.case_num['1'] + ')', value: '1' },
                            { text: _vm.status_name[2] + ' (' + _vm.case_num['2'] + ')', value: '2' },
                            { text: _vm.status_name[3] + ' (' + _vm.case_num['3'] + ')', value: '3' },
                            { text: _vm.status_name[4] + ' (' + _vm.case_num['4'] + ')', value: '4' },
                            { text: _vm.status_name[5] + ' (' + _vm.case_num['5'] + ')', value: '5' } ],"button-variant":"outline-primary","size":"sm","name":"radio-btn-outline","buttons":""},on:{"change":function($event){_vm.currentPage = 1;_vm.$refs.table.refresh()}},model:{value:(_vm.select_status),callback:function ($$v) {_vm.select_status=$$v},expression:"select_status"}}):_c('b-select',{attrs:{"options":[
                            { text: _vm.status_name[6] + ' (' + _vm.case_num['-1'] + ')', value: '-1' },
                            { text: _vm.status_name[0] + ' (' + _vm.case_num['0'] + ')', value: '0' },
                            { text: _vm.status_name[1] + ' (' + _vm.case_num['1'] + ')', value: '1' },
                            { text: _vm.status_name[2] + ' (' + _vm.case_num['2'] + ')', value: '2' },
                            { text: _vm.status_name[3] + ' (' + _vm.case_num['3'] + ')', value: '3' },
                            { text: _vm.status_name[4] + ' (' + _vm.case_num['4'] + ')', value: '4' },
                            { text: _vm.status_name[5] + ' (' + _vm.case_num['5'] + ')', value: '5' } ]},on:{"change":function($event){_vm.currentPage = 1;_vm.$refs.table.refresh()}},model:{value:(_vm.select_status),callback:function ($$v) {_vm.select_status=$$v},expression:"select_status"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-table',{ref:"table",attrs:{"striped":"","hover":"","items":_vm.myProvider,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"stacked":"true","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-local-sorting":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(current_name)",fn:function(data){return [_c('b',{staticClass:"text-info",attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":'/case/' + data.item['case_id']}},[_vm._v(_vm._s(data.item['current_name']))])],1)]}}])})],1)],1),_c('b-row',[_c('div',{staticClass:"mx-auto"},[(_vm.perPage <= _vm.case_num[_vm.select_status])?_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.case_num[_vm.select_status],"per-page":_vm.perPage,"right":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)]),(_vm.is_login)?_c('b-row',{staticClass:"mt-4"},[(_vm.screenWidth > 576)?_c('b-col',[_c('SubmitReport',{on:{"submit_successful":function($event){_vm.currentPage = 1;_vm.$refs.table.refresh()}}})],1):_c('b-col',[_c('p',{staticClass:"text-center"},[_vm._v("暂不支持手机端网页举报 (体验太差)")])])],1):_vm._e(),_c('br')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }