<template>
    <b-container><b-card class="shadow" id="fadein">
        <b-row>
            <b-col size="sm">
                <b-form-group class="form-inline" >
                    <b-select v-model="select_game"
                              :options="[
                                  { text: '战地1 (' + case_num_game['1'] + ')', value: '1' },
                                  { text: '战地5 (' + case_num_game['5'] + ')', value: '5' },
                                  { text: '未归类 (' + case_num_game['0'] + ')', value: '0' },
                              ]"
                              @change="currentPage = 1;$refs.table.refresh()" class="mr-3"
                    />
                    <b-form-radio-group
                        v-if="screenWidth > 576"
                        id="btn-radios-2"
                        v-model="select_status"
                        :options="[
                                { text: status_name[6] + ' (' + case_num['-1'] + ')', value: '-1' },
                                { text: status_name[0] + ' (' + case_num['0'] + ')', value: '0' },
                                { text: status_name[1] + ' (' + case_num['1'] + ')', value: '1' },
                                { text: status_name[2] + ' (' + case_num['2'] + ')', value: '2' },
                                { text: status_name[3] + ' (' + case_num['3'] + ')', value: '3' },
                                { text: status_name[4] + ' (' + case_num['4'] + ')', value: '4' },
                                { text: status_name[5] + ' (' + case_num['5'] + ')', value: '5' },
                            ]"
                        button-variant="outline-primary"
                        size="sm"
                        name="radio-btn-outline"
                        buttons
                        @change="currentPage = 1;$refs.table.refresh()"
                        ref="selection"
                    />
                    <b-select v-model="select_status" v-else
                              :options="[
                                { text: status_name[6] + ' (' + case_num['-1'] + ')', value: '-1' },
                                { text: status_name[0] + ' (' + case_num['0'] + ')', value: '0' },
                                { text: status_name[1] + ' (' + case_num['1'] + ')', value: '1' },
                                { text: status_name[2] + ' (' + case_num['2'] + ')', value: '2' },
                                { text: status_name[3] + ' (' + case_num['3'] + ')', value: '3' },
                                { text: status_name[4] + ' (' + case_num['4'] + ')', value: '4' },
                                { text: status_name[5] + ' (' + case_num['5'] + ')', value: '5' },
                            ]"
                              @change="currentPage = 1;$refs.table.refresh()"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table ref="table" striped hover :items="myProvider" :fields="fields" :per-page="perPage" :current-page="currentPage" stacked="true"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         no-local-sorting>
                    <template v-slot:cell(current_name)="data">
                        <b class="text-info" href="#"><router-link :to="'/case/' + data.item['case_id']">{{ data.item['current_name'] }}</router-link></b>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <div class="mx-auto">
                    <b-pagination v-if="perPage <= case_num[select_status]"
                                  v-model="currentPage"
                                  :total-rows="case_num[select_status]"
                                  :per-page="perPage"
                                  class="my-0"
                                  right
                    />
            </div>
        </b-row>
        <b-row v-if="is_login" class="mt-4">
            <b-col v-if="screenWidth > 576">
                <SubmitReport @submit_successful="currentPage = 1;$refs.table.refresh()"/>
            </b-col>
            <b-col v-else>
                <p class="text-center">暂不支持手机端网页举报 (体验太差)</p>
            </b-col>
        </b-row>
    <br></b-card></b-container>

</template>

<script>
import {request} from "@/api";
import SubmitReport from "@/components/SubmitReport";
import {is_login} from '@/cookies'

export default {
    components: {SubmitReport},
    data: function() {
        return {
            fields: [
                {key: "current_name", label: "当前ID"},
                {key: "current_status", label: "判定状态"},
                {key: "created_at", label: "创建时间", sortable: true, sortDirection: 'desc'},
                {key: "last_reply_at", label: "回复时间", sortable: true},
                {key: "last_modified_at", label: "判决时间", sortable: true},
            ],
            sortBy: 'created_at',
            sortDesc: true,
            currentPage: 1,
            perPage: 25,
            pageOptions: [10, 15, 25, 50],
            maxPage: 1,
            select_status: "-1",
            case_num: {
                '-1': 0,
                '0': 0,
                '1': 0,
                '2': 0,
                '3': 0,
                '4': 0,
                '5': 0,
                '6': 0
            },
            status_name: ["未处理", "已封禁", "证据不足", "自证通过", "自证中", "刷枪", "全部"],
            is_login: is_login(),
            screenWidth: null,  //屏幕尺寸，
            select_game: '1',
            case_num_game: {
                '1': 0,
                '5': 0,
                '0': 0
            },
        }
    },
    methods: {
        async get_case_list(pg_size = 25, current_status = -1, pn = 1, game = 1, order = "created_at", direction = true){
            let order_list = {
                "created_at": "case_id",
                "last_reply_at": "last_reply_time",
                "last_modified_at": "last_mod_time"
            }
            let order_for_query = `${order_list[order]}`
            if (direction) {
                order_for_query = `-${order_list[order]}`
            }
            // "/case/all?game=" + game + "&pn=" + pn + "&size=" + pg_size + "&status=" + current_status + "&order=" + order_for_query
            const response = await request('GET', `/case/all?game=${game}&pn=${pn}&size=${pg_size}&status=${current_status}&order=${order_for_query}`)
            this.case_num = response["num"]
            this.maxPage = response["max_pn"]
            const data = response["data"]
            let new_data = []
            for (let i in data){
                let tmp = data[i]
                let date = new Date(tmp['created_at'])
                tmp['created_at'] = date.toLocaleDateString() + " " + date.toLocaleTimeString()
                tmp['current_status'] = this.status_name[tmp['current_status']]
                if (tmp['last_reply_at'] === null) {
                    tmp['last_reply_at'] = "N/A"
                } else {
                    date = new Date(tmp['last_reply_at'])
                    tmp['last_reply_at'] = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
                }
                if (tmp['last_modified_at'] === null) {
                    tmp['last_modified_at'] = "N/A"
                } else {
                    date = new Date(tmp['last_modified_at'])
                    tmp['last_modified_at'] = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
                }
                new_data.push(tmp)
            }
            return new_data
        },
      myProvider(ctx, callback) {
            this.get_case_list(ctx.perPage, this.select_status, ctx.currentPage, this.select_game, this.sortBy, this.sortDesc)
                .then(data => {
                    callback(data)
                    if (this.select_status !== "-1"){
                        // sortable: true
                        this.fields = [
                            {key: "current_name", label: "当前ID"},
                            {key: "created_at", label: "创建时间", sortable: true, sortDirection: 'desc'},
                            {key: "last_reply_at", label: "回复时间", sortable: true},
                            {key: "last_modified_at", label: "判决时间", sortable: true},
                        ]
                    } else {
                        this.fields = [
                            {key: "current_name", label: "当前ID"},
                            {key: "current_status", label: "判定状态"},
                            {key: "created_at", label: "创建时间", sortable: true, sortDirection: 'desc'},
                            {key: "last_reply_at", label: "回复时间", sortable: true},
                            {key: "last_modified_at", label: "判决时间", sortable: true},
                        ]
                    }
                    })
                .catch(() => {
                    callback([])
                })
          return null
        }
    },
    mounted () {
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {   //屏幕尺寸变化就重新赋值
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
    created() {
        request('GET', "/case/game_num")
            .then((data) => {
                this.case_num_game = data
            })
    }
}
</script>